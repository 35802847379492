@import '~@/common.scss';

.pg-guild-invite-phone-verification {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .cmp-header {
        &__title {
            color: #000000;
        }

        &__arrow {
            img {
                width: px2rem(24px);
                height: px2rem(24px);
            }
        }
    }

    &__content {
        padding: 0 px2rem(37px);
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    &__top-tips {
        font-size: px2rem(14px);
        color: #565656;
        text-align: center;
        margin-top: px2rem(35px);
        margin-bottom: px2rem(96px);
        display: flex;
        flex-direction: column;
    }

    &__resend {
        width: px2rem(126px);
        height: px2rem(40px);
        border-radius: px2rem(9999px);
        background: linear-gradient(133deg, #6EF66E 0%, #41CEBC 68%);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: px2rem(12px);
        color: #FFFFFF;
        margin: px2rem(30px) auto;
    }

    &__center-tips {
        font-size: px2rem(12px);
        color: #565656;
        text-align: center;
        margin: px2rem(35px) 0;
        padding: 0 px2rem(90px);
        box-sizing: border-box;
    }

    &__input-box {
        display: flex;
        justify-content: center;
    }

    &__next-btn {
        height: px2rem(40px);
        background: #D9D9D9;
        border-radius: px2rem(9999px);
        text-align: center;
        font-size: px2rem(14px);
        color: #FFFFFF;
        line-height: px2rem(40px);
        margin-bottom: px2rem(100px);

        &.can-next {
            background: linear-gradient(133deg, #6EF66E 0%, #41CEBC 68%);
            box-shadow: 0 px2rem(8px) px2rem(20px) rgba($color: #41CEBC, $alpha: 0.6);
        }
    }

    &__bottom-tips {
        font-size: px2rem(12px);
        color: #565656;
        text-align: center;
        margin-top: auto;
        margin-bottom: px2rem(40px);

        span {
            color: rgba(218, 218, 218, 1);
            text-decoration: underline;
        }
    }
}

.mirror .pg-guild-invite-phone-verification {}