@import '~@/common.scss';

.pg-ios-update {
  width: 100%;
  height: px2rem(812px);
  position: relative;
  background: url('~@/images/update/update-ios.png') no-repeat center/cover;
  &__btn {
    position: absolute;
    width: px2rem(214px);
    height: px2rem(83px);
    object-fit: contain;
    left: 50%;
    transform: translateX(-50%);
    top: px2rem(248px);
  }
}
