@import '../common.scss';

.header-root{
  width: 100%;
}

.cmp-header {
  position: relative;
  top: 0;
  z-index: 100;
  width: 100%;
  &__arrow, &__more {
    position: absolute;
    bottom: 0;
    width: px2rem(45px);
    height: px2rem(45px);
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: px2rem(16px);
      height: px2rem(16px);
    }
  }
  &__arrow {
    left: 0;
  }
  &__more {
    right: 0;
  }
  &__title {
    // height: 100%;
    line-height: px2rem(45px);
    text-align: center;
    color: white;
    font-size: px2rem(18px);
  }
  &__more {
    &-pane {
      position: absolute;
      right: px2rem(10px);
      top: px2rem(45px);
      background-color: rgba($color: #000000, $alpha: .6);
      border-radius: px2rem(6px);
      &::before {
        content: '';
        position: absolute;
        top: px2rem(-4px);
        right: px2rem(10px);
        transform: rotate(135deg);
        border-top: px2rem(8px) solid transparent; 
        border-left: px2rem(8px) solid rgba($color: #000000, $alpha: .6);  
      }
    }
    &-item {
      width: px2rem(106px);
      height: px2rem(50px);
      line-height: px2rem(50px);
      text-align: center;
      color: white;
      font-size: px2rem(13px);
    }
  }
}

.mirror {
  .cmp-header {
    flex-direction: row-reverse;
    &__arrow {
      left: unset;
      right: 0;
      img {
        transform: rotateZ(180deg);
      }
    }
    &__more {
      right: unset;
      left: 0;
      &-pane {
        right: unset;
        left: px2rem(10px);
        &::before {
          top: px2rem(-4px);
          right:px2rem(90px);
        }
      }
    }
  }
}