@import '~@/common.scss';

.pg-guild-invite-phone-create-psw {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .cmp-header {
        &__title {
            color: #000000;
        }

        &__arrow {
            img {
                width: px2rem(24px);
                height: px2rem(24px);
            }
        }
    }

    &__content {
        padding: 0 px2rem(37px);
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    &__top-tips {
        font-size: px2rem(14px);
        color: #565656;
        text-align: center;
        margin-top: px2rem(35px);
        padding: 0 px2rem(25px);
        box-sizing: border-box;
    }

    &__input-box {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: px2rem(14px);
        color: #565656;
        padding: px2rem(6px) px2rem(15px);
        box-sizing: border-box;
        border-top: px2rem(1px) solid #DADADA;
        border-bottom: px2rem(1px) solid #DADADA;
        margin: px2rem(98px) 0;

        .adm-input-element {
            font-size: px2rem(14px);
            color: #565656;
        }

        &-eye {
            flex: none;
            cursor: pointer;

            svg {
                display: block;
                font-size: var(--adm-font-size-7);
            }
        }
    }

    &__next-btn {
        height: px2rem(40px);
        background: #D9D9D9;
        border-radius: px2rem(9999px);
        text-align: center;
        font-size: px2rem(14px);
        color: #FFFFFF;
        line-height: px2rem(40px);
        margin-bottom: px2rem(100px);

        &.can-next {
            background: linear-gradient(133deg, #6EF66E 0%, #41CEBC 68%);
            box-shadow: 0 px2rem(8px) px2rem(20px) rgba($color: #41CEBC, $alpha: 0.6);
        }
    }

    &__bottom-tips {
        font-size: px2rem(12px);
        color: #565656;
        text-align: center;
        margin-top: auto;
        margin-bottom: px2rem(40px);

        span {
            color: rgba(218, 218, 218, 1);
            text-decoration: underline;
        }
    }
}

.mirror .pg-guild-invite-phone-create-psw {
    direction: rtl;

    &__input-box {

        .adm-input-element {
            text-align: right;
        }
    }
}