body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hide {
  display: none !important;
}

.pg {
  min-height: 100vh;
  box-sizing: border-box;
}

.pg.loading {
  width: 100vw;
  overflow: hidden;
}

.fix-popup-safe-area .adm-popup-body.adm-popup-body-position-bottom {
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
/* 键盘弹出的一个占位div设置 */
.place {
  min-height: 100px;
  flex-grow: 1;
}
/* 激活按钮样式 */
.activate-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #1ff5a9, #00e0d5) !important;
  color: #ffffff !important;
  font-weight: 500;
}
/* 未激活按钮样式 */
.unactivated-btn {
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.3733333333rem;
  font-weight: 500;
  color: #b7b7b7;
  background: rgba(240, 240, 240, 1);
}
/* 常用页面基础样式 */
.pg-fa {
  min-height: 100vh;
  background: #fafafa;
  box-sizing: border-box;
  padding: 0 0.4rem 0.8rem;
  display: flex;
  flex-direction: column;
}

.mirror {
  direction: rtl;
}

/* antd-mobile的Toast提示英文单词换行问题 */
.adm-toast-mask .adm-toast-main {
  /* word-break: break-all;单词会不规则断行 */
  word-break: break-word;
  text-align: center;
}
