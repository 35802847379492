@import '~@/common.scss';

.cmp-invite-phone-register-country {
  height: px2rem(509px);
  width: px2rem(375px);
  background-color: #fff;
  border-radius: px2rem(12px) px2rem(12px) 0 0;
  box-sizing: border-box;

  &__input-box {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: px2rem(14px);
    color: #565656;
    padding: px2rem(6px) px2rem(16px);
    box-sizing: border-box;
    border-top: px2rem(1px) solid #DADADA;
    border-bottom: px2rem(1px) solid #DADADA;
    margin-top: px2rem(74px);
    margin-bottom: px2rem(98px);

    div {
      display: flex;
      align-items: center;
    }

    img {
      width: px2rem(23px);
      height: px2rem(24px);
      object-fit: contain;
    }

    span {
      margin: 0 px2rem(7px);
    }

    .adm-input {
      padding-left: px2rem(20px);

      input {
        font-size: px2rem(14px);
        color: #565656;
      }
    }
  }

  &-list {
    height: px2rem(405px);
    overflow-y: auto;
    padding: 0 px2rem(22px);
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: px2rem(15px) 0;
    border-bottom: #eee px2rem(1px) solid;

    &-left {
      display: flex;
      align-items: center;

      span {
        margin: 0 px2rem(16px);
      }

      img {
        width: px2rem(30px);
        height: px2rem(20px);
      }
    }

    &-right {
      font-size: px2rem(13px);
    }

  }

  &-title {
    font-size: px2rem(16px);
    font-weight: bold;
    color: #1a1a1a;
    line-height: px2rem(25px);
    display: flex;
    justify-content: center;
    padding: px2rem(20px) 0;
    box-sizing: border-box;
  }

  &-search {
    padding: 0 px2rem(12px);
  }

  &.country-popup .adm-popup-body {
    background-color: transparent !important;
  }
}

.mirror {
  & .cmp-invite-phone-register-country {
    direction: rtl;

    .adm-search-bar {
      .adm-input-element {
        direction: ltr;
      }

      &-input-box {
        padding-left: unset;
        padding-right: var(--padding-left);
      }
    }

    &__input-box {

      .adm-input {
        padding-left: unset;
        padding-right: px2rem(20px);

        &-clear {
          margin-left: unset;
          margin-right: px2rem(8px);
        }
      }
    }
  }
}