@import '~@/common.scss';

.pg-guild-invite-friends {
    min-height: 100vh;
    position: relative;
    background-color: #000;
    display: flex;
    flex-direction: column;

    video {
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    &__header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: px2rem(15px);
        z-index: 2;
        position: relative;

        img {
            width: px2rem(120px);
            height: px2rem(120px);
            margin-bottom: px2rem(15px);
            border-radius: 50%;
            object-fit: cover;
        }

        span {
            font-weight: 500;
            font-size: px2rem(24px);
            color: #FFFFFF;
            margin-bottom: px2rem(6px);

            &:last-child {
                font-weight: 400;
                font-size: px2rem(14px);
            }
        }
    }

    &__icons {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-top: px2rem(30px);
        z-index: 2;
        position: relative;

        div {
            width: px2rem(116px);
            height: px2rem(120px);
            background: url(~@/images/invite-icon-frame.webp) no-repeat center/cover;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: px2rem(76px);
                height: px2rem(76px);
                object-fit: cover;
            }
        }
    }

    &__btns {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: px2rem(45px);
        z-index: 2;
        position: relative;

        &-item {
            width: px2rem(300px);
            height: px2rem(44px);
            border: px2rem(1px) solid #FFFFFF;
            border-radius: px2rem(99999px);
            margin-bottom: px2rem(20px);
            display: flex;
            align-items: center;
            padding: 0 px2rem(34px);
            box-sizing: border-box;
            position: relative;

            img {
                width: px2rem(20px);
                height: px2rem(20px);
            }

            span {
                font-weight: 500;
                font-size: px2rem(14px);
                color: #FFFFFF;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    &__download {
        margin: px2rem(90px) auto px2rem(75px);
        width: px2rem(214px);
        height: px2rem(83px);
        background: url(~@/images/download-btn.webp) no-repeat center/cover;
        font-weight: 900;
        font-size: px2rem(16px);
        line-height: px2rem(75px);
        color: #FFFF00;
        text-shadow: 0px px2rem(2px) px2rem(1px) rgba(0, 0, 0, 0.61);
        text-align: center;
        z-index: 2;
        position: relative;

        span:last-child {
            font-size: px2rem(22px);
        }
    }

    &__tips {
        display: flex;
        flex-direction: column;
        margin: 0 px2rem(15px);
        padding-bottom: px2rem(15px);
        z-index: 2;
        position: relative;
        margin-top: auto;

        span {
            font-weight: 500;
            font-size: px2rem(16px);
            color: #FFFFFF;
            margin-bottom: px2rem(10px);

            &:last-child {
                font-weight: 500;
                font-size: px2rem(13px);
                color: #B7B7B7;
            }
        }
    }
}